import travrondenTheme from '@trmediaab/theme-travronden';
import { createTheme } from '@trmediaab/zebra';

import { borderWidths, radii } from './border';
import breakpoints from './breakpoints';
import colors from './colors';
import sizes from './sizes';
import space from './space';
import { fonts, fontSizes, fontWeights, lineHeights } from './typography';
import zIndices from './zIndices';

// Hacks to make the purchase flow fit in better on Travronden Spel
const purchaseFlowHacks = {
  components: {
    button: {
      shape: {
        square: {
          variants: {
            xlarge: {
              fontSize: 1,
            },
          },
        },
      },
      combinations: {
        cta: {
          colorScheme: 'primary',
          shape: 'square',
        },
      },
    },
  },
};

const theme = createTheme([
  travrondenTheme,
  purchaseFlowHacks,
  {
    borderWidths,
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    radii,
    sizes,
    space,
    zIndices,
  },
]);

export default theme;
