const defaultHover = {
  '&:hover, &:focus': {
    'text-decoration': 'underline',
  },
};

const headingHover = {
  'text-decoration': 'underline',
};

const anchor = {
  common: {
    outline: 'none',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
  },
  variants: {
    default: {
      color: 'link.base',
      textDecoration: 'none',
      ...defaultHover,
    },
    matchText: {
      color: 'inherit',
      textDecoration: 'none',
      ...defaultHover,
    },
    matchTextNoHover: {
      color: 'inherit',
      textDecoration: 'none',
    },
    heading: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover h1, &:focus h1': headingHover,
      '&:hover h2, &:focus h2': headingHover,
      '&:hover h3, &:focus h3': headingHover,
      '&:hover h4, &:focus h4': headingHover,
    },
  },
};

export default anchor;
