const transitionDuration = ['150ms', '300ms', '450ms', '600ms'];
transitionDuration.fast = transitionDuration[0];
transitionDuration.normal = transitionDuration[1];
transitionDuration.slow = transitionDuration[2];
transitionDuration.slowest = transitionDuration[3];

const transitionTimingFunction = {
  easeInOut: 'cubic-bezier(0.5, 0, 0.25, 1)',
  easeOut: 'cubic-bezier(0, 0, 0.25, 1)',
  easeIn: 'cubic-bezier(0.5, 0, 1, 1)',
};

const colors = {
  black: '#000000',
  white: '#ffffff',
  brands: {
    travronden: '#f32016',
    travrondenSpel: '#090a3a',
    trmedia: '#C7051F',
    facebook: '#3b5998',
    twitter: '#38A1F3',
  },
};

const space = [
  0, //   0
  4, //   1
  8, //   2
  16, //  3
  32, //  4
  64, //  5
  128, // 6
  256, // 7
  512, // 8
];
space['2,5'] = 12;
space['3,5'] = 24;
space['4,5'] = 48;
space['5,5'] = 96;

const ems = [
  0,
  '0.25em',
  '0.5em',
  '0.75em',
  '1em',
  '1.5em',
  '2em',
  '3em',
  '4em',
];

const sizes = {
  space,
};

const baseTheme = {
  colors,
  ems,
  transitionDuration,
  space,
  sizes,
  transitionTimingFunction,
};

export default baseTheme;
