/**
 * Deep add `px` to object values
 *
 * @param {object} obj
 */
const appendPx = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = Array.isArray(obj[key])
      ? obj[key].map(v => `${v}px`)
      : typeof obj[key] === 'object'
      ? appendPx(obj[key])
      : `${obj[key]}px`;
    return acc;
  }, {});

export default appendPx;
