const px = ['pageGutters.base', null, null, 'pageGutters.lg', 'pageGutters.xl'];

const container = {
  common: {
    display: 'flex',
    flexDirection: 'column',
    mx: 'auto',
  },
  variants: {
    default: {
      maxWidth: ['columns.mainLarge', null, null, null, 'columns.max'],
      px,
    },
    small: {
      maxWidth: 'columns.mainSmall',
      px,
    },
    medium: {
      maxWidth: 'columns.mainMedium',
      px,
    },
    large: {
      maxWidth: 'columns.mainLarge',
      px,
    },
  },
};

export default container;
