import { baseTheme } from '@trmediaab/zebra';

const { colors: baseColors } = baseTheme;

const primary = baseColors.brands.travrondenSpel;

const colors = {
  primary /** Dark blue */,
  accent: '#DF4137',
  black: '#000',
  bluelight: '#358bcb',
  blue: '#007cd5',
  bluedark: '#002349',
  lightgrey: '#999',
  yellow: '#f5c00c',
  orange: '#ff431d',
  red: '#f22b2a',
  change: '#d20000',
  white: '#fff',
  bgsite: '#fff',
  bgsiteSecondary: '#F1F1F1',
  bgsitecomplementary: '#f6f4f3',
  bgdark: '#191c22',
  bginfo: '#fff7c7',
  bginverted: '#f2f2f2',
  bodyText: '#222222',

  text: {
    base: '#222222',
  },

  border: '#ddd',
  borderdark: '#4a4a4a',
  buttonprimary: '#007cd5',
  textmeta: '#888',
  textsuccess: '#270',
  textfail: '#d8000c',
  textlight: '#fff',
  link: '#3E78B2',
  linkactive: '#007cd5',
  travrondenred: '#f41f16',
};

export default colors;
