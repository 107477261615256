import { createGlobalStyle } from 'styled-components';

// Inspired by https://github.com/necolas/normalize.css,
// https://hankchizljaw.com/wrote/a-modern-css-reset/ and
// Material UI (https://github.com/mui-org/material-ui/blob/aa4e2dbf3ad98355d2e43cdd281f0ea2c2204046/packages/material-ui/src/CssBaseline/CssBaseline.js#L8)

// A subset of Normalize.css to fix browser inconsistencies
const normalizeBrowsers = `

  html {
    -webkit-text-size-adjust: 100%;
  }

  main, details {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  textarea {
    overflow: auto;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
`;

// Reset browser defaults
const resetStyles = `

  /* Border box on all elements */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding on styled lists. */
  ul,
  ol {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0
  }

`;

const defaultStyles = `

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    /* Set width in case body is positioned (e.g fixed) */ 
    width: 100%; 
    scroll-behavior: smooth;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Remove list styles on ul, ol elements */
  ul,
  ol {
    list-style: none;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /* Save printer ink */
  @media print {
    body {
      background-color: #fff;
    }
  }
`;

export default createGlobalStyle`
  ${normalizeBrowsers}
  ${resetStyles}
  ${defaultStyles}
`;
