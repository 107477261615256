const text = {
  root: {
    variants: {
      default: {
        fontSize: 'base',
        fontFamily: 'primary',
        fontWeight: 'base',
        lineHeight: 'base',
        color: 'text.base',
      },
    },
  },
  em: {
    variants: {
      default: {
        fontStyle: 'italic',
      },
    },
  },
  strong: {
    variants: {
      default: {
        fontWeight: 'bold',
      },
      stronger: {
        fontWeight: 'extraBold',
      },
    },
  },
  small: {
    variants: {
      default: {
        fontSize: '80%',
        lineHeight: 'medium',
      },
    },
  },
};

export default text;
