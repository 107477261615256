import PropTypes from 'prop-types';
import {
  StyleSheetManager,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';

import GlobalStyles from './GlobalStyles';

const ThemeProvider = ({
  children,
  theme,
  disableGlobalStyles,
  disableVendorPrefixes,
}) => (
  <StyleSheetManager disableVendorPrefixes={disableVendorPrefixes}>
    <StyledComponentsThemeProvider theme={theme}>
      {!disableGlobalStyles && <GlobalStyles />}
      {children}
    </StyledComponentsThemeProvider>
  </StyleSheetManager>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  disableGlobalStyles: PropTypes.bool,
  disableVendorPrefixes: PropTypes.bool,
};

ThemeProvider.defaultProps = {
  disableGlobalStyles: false,
  disableVendorPrefixes: false,
};

export default ThemeProvider;
