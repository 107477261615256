import merge from 'deepmerge';

import baseTheme from './baseTheme';

const arrayOverwrite = (_, sourceArray) => sourceArray;

const validateThemeObject = obj => {
  if (obj.borders != null) {
    throw new Error(`
      Invalid theme field 'borders'.
      
      By default Styled System connects the border prop (incl borderTop etc) to the 
      borders theme field and outputs border shorthand CSS. Due to CSS specificity 
      and browser implementation these rules will override border colors set in 
      variants resulting in a color value of 'initial'.

      To workaround this the border shorthand props has been overriden to only 
      output border-width declarations. Use 'borderWidths' to define a scale for 
      widhts and set border-style in variants, eg the common default variant can 
      be used to set the border-style to 'solid' on all BorderBox components. 
    `);
  }

  return obj;
};

/**
 *
 * @param {Object|Object[]} theme
 * @returns
 */
const createTheme = theme => {
  const arr = [baseTheme];
  if (Array.isArray(theme)) {
    arr.push(...theme.map(t => validateThemeObject(t)));
  } else {
    arr.push(theme);
  }
  return merge.all(arr, {
    arrayMerge: arrayOverwrite,
  });
};

export default createTheme;
