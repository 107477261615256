import { useEffect } from 'react';

/**
 * A hook for listening to events on the window object
 *
 * @param {array|string} eventTypes
 * @param {function} callback
 */
const useWindowEvents = (eventTypes, callback) => {
  useEffect(() => {
    let raf = false;

    const update = () => {
      raf = false;
      callback();
    };

    const updateLater = () => {
      if (!raf) {
        requestAnimationFrame(update);
        raf = true;
      }
    };

    const options = { capture: true, passive: true };

    const types = Array.isArray(eventTypes) ? [...eventTypes] : [eventTypes];
    if (types.includes('resize') && !types.includes('orientationchange')) {
      types.push('orientationchange');
    }

    types.forEach(type => window.addEventListener(type, updateLater, options));

    return () => {
      types.forEach(type =>
        window.removeEventListener(type, updateLater, options),
      );
    };
  }, [eventTypes, callback]);
};

export default useWindowEvents;
