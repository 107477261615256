export default class LocalTokenStorage {
  constructor(prefix, localStorage) {
    this.localStorage = localStorage;
    this.prefix = prefix;
  }

  get(key) {
    let item = this.localStorage.getItem(`${this.prefix}-${key}`);
    try {
      item = JSON.parse(item);
    } catch (error) {
      return item;
    }
    return item;
  }

  set(key, val) {
    const toSave = JSON.stringify(val);
    return this.localStorage.setItem(`${this.prefix}-${key}`, toSave);
  }

  remove(key) {
    return this.localStorage.removeItem(`${this.prefix}-${key}`);
  }
}
