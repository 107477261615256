import { canUseDOM } from 'exenv';
import { useCallback, useState } from 'react';

import useWindowEvents from './useWindowEvents';

const createSize = breakpointsMap => {
  if (!canUseDOM) {
    return;
  }

  const width =
    window.innerWidth && document.documentElement.clientWidth
      ? Math.min(window.innerWidth, document.documentElement.clientWidth)
      : window.innerWidth ||
        document.documentElement.clientWidth ||
        document.querySelectorAll('body')[0].clientWidth;

  const height =
    window.innerHeight && document.documentElement.clientHeight
      ? Math.min(window.innerHeight, document.documentElement.clientHeight)
      : window.innerHeight ||
        document.documentElement.clientHeight ||
        document.querySelectorAll('body')[0].clientHeight;

  let breakpoints;
  if (breakpointsMap != null) {
    breakpoints = Object.keys(breakpointsMap).reduce((acc, name) => {
      acc[name] = breakpointsMap[name] <= width;
      return acc;
    }, {});
  }

  return {
    width,
    height,
    breakpoints,
  };
};

/**
 * A hook for reading the window's inner width and height. If a
 * breakpoints map is provided the returned object will also contain
 * a breakpoints object with a boolean value for each breakpoint,
 * true if the current width is smaller or equal otherwise false.
 *
 * @param {object} breakpointsMap Optional object of breakpoints,
 * e.g
 * {
 *   small: 576,
 *   tablet: 768
 * }
 */
const useWindowSize = breakpointsMap => {
  const [windowSize, setWindowSize] = useState(createSize(breakpointsMap));

  const onResize = useCallback(() => {
    setWindowSize(createSize(breakpointsMap));
  }, [breakpointsMap]);

  useWindowEvents('resize', onResize);

  return windowSize;
};

export default useWindowSize;
