const light = 300;
const regular = 400;
const semiBold = 600;
const bold = 700;

export const fontWeights = {
  base: regular,
  light,
  regular,
  semiBold,
  bold,
};

export const fonts = {
  primary: [
    'Open Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ]
    .map(font => `'${font}'`)
    .join(', '),
  secondary: [
    'GuardianEgyptian',
    'Constantia',
    'Lucida Bright',
    'Lucidabright',
    'Lucida Serif',
    'Lucida',
    'DejaVu Serif',
    'Bitstream Vera Serif',
    'Liberation Serif',
    'Georgia',
    'serif',
  ]
    .map(font => `'${font}'`)
    .join(', '),
};

// TODO
export const fontSizes = [
  12, // 0
  14, // 1
  16, // 2
  18, // 3
  24, // 4
  28, // 5
  32, // 6
  58, // 7
  70, // 8
];

// Aliases
fontSizes.base = fontSizes[2];

// TODO
export const lineHeights = {
  base: 1.4,
};
