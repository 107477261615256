const breakpoints = ['576px', '768px', '1020px', '1200px'];

// aliases
breakpoints.xs = '575px'; // // Use with max media to target anything below `phone`
breakpoints.sm = breakpoints.phone = breakpoints[0];
breakpoints.md = breakpoints.tablet = breakpoints[1];
breakpoints.lg = breakpoints.desktop = breakpoints[2];
breakpoints.xl = breakpoints.giant = breakpoints[3];

export default breakpoints;
