const divider = {
  variants: {
    default: {
      bg: 'divider',
      height: '1px',
    },
    vertical: {
      bg: 'divider',
      width: '1px',
      maxHeight: '100vh',
    },
  },
};

export default divider;
