import anchor from './anchor';
import borderBox from './borderBox';
import button from './button';
import container from './container';
import divider from './divider';
import heading from './heading';
import { list, listItem } from './list';
import text from './text';
import textInput from './textInput';

const variants = {
  anchor,
  borderBox,
  button,
  container,
  divider,
  heading,
  list,
  listItem,
  text,
  textInput,
};

export default variants;
