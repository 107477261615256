const textInput = {
  common: {
    borderColor: 'border',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 1,
    boxShadow: 'textInput',
    width: '100%',
    '&:focus': {
      outline: 'none',
      boxShadow: 'focus',
    },
  },
  line: {
    variants: {
      default: {
        height: 'buttonHeights.xlarge',
        lineHeight: 'buttonHeights.xlarge',
        pl: '2,5',
        pr: '2,5',
      },
    },
  },
  area: {
    variants: {
      default: {
        height: '150px',
        p: '2,5',
      },
    },
  },
};

export default textInput;
