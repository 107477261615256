const list = {
  ordered: {
    variants: {
      default: {
        counterReset: 'listCounter',
      },
    },
  },
};

const listItem = {
  dividedVertical: {
    variants: {
      default: {
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: 'divider',
      },
    },
  },
  dividedHorizontal: {
    variants: {
      default: {
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
        borderLeftColor: 'divider',
      },
    },
  },
  bulleted: {
    common: {
      position: 'relative',
    },
    variants: {
      default: {
        pl: 3,
        '&::before': {
          position: 'absolute',
          left: 0,
          display: 'inline-block',
          content: '" "',
          size: '8px',
          mt: '0.5em',
          bg: 'primary',
        },
      },
      dots: {
        pl: 2,
        '&::before': {
          position: 'absolute',
          left: 0,
          display: 'inline-block',
          content: '"•"',
          color: 'primary',
        },
      },
      checks: {
        pl: '3,5',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: '18px',
          height: '18px',
          mt: ['2px', null, null, '4px'],
          bg: 'accent',
          borderRadius: 'circle',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          left: '6px',
          top: ['4px', null, null, '6px'],
          transform: 'rotate(45deg)',
          width: '6px',
          height: '12px',
          borderColor: 'white',
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
          borderRightWidth: '2px',
          borderRightStyle: 'solid',
        },
      },
    },
  },
  numbered: {
    variants: {
      default: {
        position: 'relative',
        pl: '29px',
        '&::before': {
          position: 'absolute',
          left: 0,
          display: 'inline-block',
          counterIncrement: 'listCounter',
          content: "counter(listCounter) '.'",
          width: '21px',
          color: 'primary',
          fontFamily: 'primary',
          fontWeight: 'semiBold',
          textAlign: 'right',
        },
      },
      factbox: {
        display: 'flex',
        alignItems: 'center',
        'flex-shrink': 0,
        'flex-wrap': 'wrap',
        '&::before': {
          counterIncrement: 'listCounter',
          content: 'counter(listCounter)',
          bg: 'accent',
          color: 'white',
          borderRadius: 'circle',
          mr: 2,
          size: '20px',
          fontWeight: 'semiBold',
          fontSize: 0,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
        },
      },
    },
  },
};

export { list, listItem };
