const colorScheme = {
  primary: {
    bg: 'slate',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'accent',
    },
  },
  secondary: {
    bg: 'greys.1',
    color: 'text.base',
    '&:hover:not(:disabled)': {
      bg: 'accent',
      color: 'white',
    },
  },
  tertiary: {
    bg: 'accent',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'hover.darkerAccent',
      color: 'hover.whiteTrans03',
    },
  },
  lightGrey: {
    bg: 'grey95',
    color: 'slate',
    '&:hover:not(:disabled)': {
      bg: 'hover.grey95',
    },
  },
  darkGrey: {
    bg: 'greys.2',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'hover.grey2',
      color: 'hover.whiteTrans02',
    },
  },
  darkAlpha: {
    bg: 'darkAlpha.1',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'hover.darkAlpha1',
      color: 'hover.whiteTrans03',
    },
  },
  outline: {
    bg: 'transparent',
    color: 'slate',
    border: '1px solid',
    borderColor: 'slate',
    '&:hover:not(:disabled)': {
      bg: 'hover.whiteTrans02',
    },
  },
  outlineFaded: {
    bg: 'transparent',
    color: 'text.dark',
    border: '1px solid',
    borderColor: 'border',
    '&:hover:not(:disabled)': {
      bg: 'hover.whiteTrans03',
    },
  },
  facebook: {
    bg: 'brands.facebook',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'hover.facebook',
      color: 'hover.whiteTrans02',
    },
  },
  twitter: {
    bg: 'brands.twitter',
    color: 'white',
    '&:hover:not(:disabled)': {
      bg: 'hover.twitter',
      color: 'hover.whiteTrans02',
    },
  },
};

const pxMap = {
  tiny: 2,
  small: '2,5',
  medium: '2,5',
  large: 3,
  xlarge: 4,
  xxlarge: 4,
};

const sizes = {
  tiny: {
    height: 'buttonHeights.tiny',
    lineHeight: 'buttonHeights.tiny',
    fontSize: 0,
  },
  small: {
    height: 'buttonHeights.small',
    lineHeight: 'buttonHeights.small',
    fontSize: 1,
  },
  medium: {
    height: 'buttonHeights.medium',
    lineHeight: 'buttonHeights.medium',
    fontSize: 1,
  },
  large: {
    height: 'buttonHeights.large',
    lineHeight: 'buttonHeights.large',
    fontSize: 'base',
  },
  xlarge: {
    height: 'buttonHeights.xlarge',
    lineHeight: 'buttonHeights.xlarge',
    fontSize: 'base',
  },
  xxlarge: {
    height: 'buttonHeights.xxlarge',
    lineHeight: 'buttonHeights.xxlarge',
    fontSize: 'base',
  },
};

const squareAndRoundSizeVariants = Object.keys(sizes).reduce((acc, key) => {
  acc[key] = {
    ...sizes[key],
    px: pxMap[key],
  };
  return acc;
}, {});

const roundSizeVariants = Object.keys(sizes).reduce((acc, key) => {
  acc[key] = {
    ...sizes[key],
    width: sizes[key].height,
  };
  return acc;
}, {});

const button = {
  common: {
    outline: 'none',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    display: 'inline-flex',
    alignItems: 'center',
    transitionDuration: 'normal',
    transitionTimingFunction: 'easeInOut',
    transitionProperty: 'background-color, color',
    userSelect: 'none',
    fontWeight: 'semiBold',
  },
  colorScheme,
  shape: {
    square: {
      common: {
        borderRadius: 1,
        justifyContent: 'center',
      },
      variants: {
        default: squareAndRoundSizeVariants.large,
        ...squareAndRoundSizeVariants,
      },
    },
    rounded: {
      common: {
        borderRadius: 3,
        justifyContent: 'center',
      },
      variants: squareAndRoundSizeVariants,
    },
    round: {
      common: {
        borderRadius: 'circle',
        justifyContent: 'center',
      },
      variants: {
        default: roundSizeVariants.large,
        ...roundSizeVariants,
      },
    },
    other: {
      variants: sizes,
    },
  },
  disabled: {
    default: {
      opacity: '0.7',
    },
  },
  combinations: {
    primary: {
      shape: 'square',
      size: 'large',
      colorScheme: 'primary',
    },
    secondary: {
      shape: 'square',
      size: 'large',
      colorScheme: 'secondary',
    },
    tertiary: {
      shape: 'square',
      size: 'large',
      colorScheme: 'tertiary',
    },
    cta: {
      shape: 'rounded',
      size: 'xlarge',
      colorScheme: 'tertiary',
    },
  },
};

export default button;
