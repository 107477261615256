/**
 * Returns a shallow copy of an object without
 * properties whose value is null.
 */
const omitNull = obj => {
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
    return obj;
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export default omitNull;
