import TruthError from './errors';

export const CSRF_STORAGE_KEY = 'csrfToken';
export const JWT_STORAGE_KEY = 'jwtToken';
export const USER_STORAGE_KEY = 'user';

const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export default class TruthClient {
  constructor({
    host,
    storage,
    csrfHeader = 'X-JWT-CSRF-Token',
    jwtHeader = 'Authorization',
    saveJwtToken = false,
  }) {
    this.host = host;
    this.storage = storage;
    this.csrfHeader = csrfHeader;
    this.jwtHeader = jwtHeader;
    this.saveJwtToken = saveJwtToken;
  }

  get csrfToken() {
    return this.storage.get(CSRF_STORAGE_KEY);
  }

  set csrfToken(newValue) {
    if (newValue == null) {
      this.storage.remove(CSRF_STORAGE_KEY);
    } else {
      this.storage.set(CSRF_STORAGE_KEY, newValue);
    }
  }

  get jwtToken() {
    return this.storage.get(JWT_STORAGE_KEY);
  }

  set jwtToken(newValue) {
    if (newValue == null) {
      this.storage.remove(JWT_STORAGE_KEY);
    } else {
      this.storage.set(JWT_STORAGE_KEY, newValue);
    }
  }

  get user() {
    return this.storage.get(USER_STORAGE_KEY);
  }

  set user(newValue) {
    if (newValue == null) {
      this.storage.remove(USER_STORAGE_KEY);
    } else {
      this.storage.set(USER_STORAGE_KEY, newValue);
    }
  }

  isAuthenticated() {
    return this.user != null;
  }

  fetch(endpoint, data, method = httpMethod.POST) {
    const url = `${this.host}/${endpoint}/`;
    const isGET = method.toUpperCase() === httpMethod.GET;
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    if (this.csrfToken != null) {
      headers.set(this.csrfHeader, this.csrfToken);
    }
    return fetch(url, {
      method,
      body: data && !isGET ? JSON.stringify(data) : undefined,
      headers,
      credentials: 'include',
    })
      .then(response => Promise.all([response.status, response.json()]))
      .then(([responseStatus, responseData]) => {
        if (responseStatus < 200 || responseStatus >= 300) {
          throw new TruthError(
            responseData.error || 'Unknown error',
            responseData.code || 'unknown',
            responseStatus,
          );
        }
        return responseData;
      })
      .then(responseData => {
        if ({}.hasOwnProperty.call(responseData, 'csrf_token')) {
          this.csrfToken = responseData.csrf_token;
        }
        if (
          this.saveJwtToken &&
          {}.hasOwnProperty.call(responseData, 'token')
        ) {
          this.jwtToken = responseData.token;
        }
        if ({}.hasOwnProperty.call(responseData, 'user')) {
          this.user = responseData.user;
        }
        return responseData;
      });
  }

  authenticate(username, password) {
    return this.fetch('auth', { username, password });
  }

  register(username, password) {
    return this.fetch('register', { username, password });
  }

  refresh() {
    return this.fetch('refresh', { token: this.jwtToken }).catch(error => {
      if (error.status === 401 || error.status === 403) {
        this.csrfToken = null;
        this.jwtToken = null;
        this.user = null;
      }
      throw error;
    });
  }

  logout() {
    return this.fetch('logout', { token: this.jwtToken }).then(response => {
      this.csrfToken = null;
      this.jwtToken = null;
      this.user = null;
      return response;
    });
  }

  updateProfile(data) {
    return this.fetch(
      'profile',
      { ...data, token: this.jwtToken },
      httpMethod.PATCH,
    );
  }
}
