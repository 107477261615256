import { canUseDOM } from 'exenv';

const PROPERTIES = [['length', 0]];

const METHODS = [
  ['key', null],
  ['getItem', null],
  ['setItem', undefined],
  ['removeItem', undefined],
  ['clear', undefined],
];

/**
 * `window.localStorage.setItem()` can throw `QuotaExceededError`s in Private
 * Browsing on iOS. And simply accessing `window.localStorage` can throw
 * `SecurityError`s if the user has restricted settings. This wrapper warns
 * instead of throwing errors, in order to reduce the noise on bugsnag.
 */
class NonThrowingLocalStorage {
  constructor(storage) {
    PROPERTIES.forEach(([name, defaultValue]) => {
      Object.defineProperty(this, name, {
        get: () => {
          try {
            return window[storage][name];
          } catch (error) {
            console.warn(`Access to ${storage}.${name} failed`, {
              error,
              defaultValue,
            });
            return defaultValue;
          }
        },
      });
    });

    METHODS.forEach(([name, defaultValue]) => {
      this[name] = function (...args) {
        try {
          return window[storage][name](...args);
        } catch (error) {
          console.warn(`Call to ${storage}.${name} failed`, {
            args,
            error,
            defaultValue,
          });
          return defaultValue;
        }
      };
    });
  }
}

class WebStorage {
  constructor(persist) {
    this.store = new NonThrowingLocalStorage(
      persist ? 'localStorage' : 'sessionStorage',
    );
  }

  get(key) {
    if (!canUseDOM) {
      return null;
    }
    let item = this.store.getItem(key);
    try {
      item = JSON.parse(item);
    } catch {
      return item;
    }
    return item;
  }

  set(key, value) {
    if (!canUseDOM) {
      return null;
    }
    return this.store.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    if (!canUseDOM) {
      return null;
    }
    return this.store.removeItem(key);
  }
}

export const localStorage = new WebStorage(true);
export const sessionStorage = new WebStorage(false);
