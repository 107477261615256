const borderBox = {
  common: {
    borderStyle: 'solid',
  },
  variants: {
    default: {
      borderColor: 'border',
    },
  },
};

export default borderBox;
