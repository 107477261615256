const heading = {
  common: {
    lineHeight: 'tight',
    textRendering: 'optimizeLegibility',
  },
  h1: {
    variants: {
      default: {
        fontSize: [6, null, 7],
        fontWeight: 'extraBold',
      },
      opinion: {
        fontSize: [6, null, 7],
        fontWeight: 'bold',
      },
      feature: {
        maxWidth: 'columns.mainMedium',
        mx: 'auto',
        fontSize: [6, null, 8],
        fontWeight: 'extraBold',
        color: 'white',
      },
      featureList: {
        fontSize: [5, null, 7],
        color: 'white',
        userSelect: 'none',
      },
      jumbo: {
        fontSize: [6, null, 8, 'jumbo'],
        fontWeight: 'extraBold',
      },
      tv: {
        fontSize: [4, null, 6],
        fontWeight: 'extraBold',
      },
    },
  },
  h2: {
    variants: {
      default: {
        fontSize: [4, null, 5],
        fontWeight: 'bold',
      },
      underlined: {
        fontSize: [4, null, 5],
        fontWeight: 'bold',
        paddingBottom: 3,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'divider',
      },
    },
  },
  h3: {
    common: {
      fontWeight: 'bold',
    },
    variants: {
      default: {
        fontSize: [3, null, 4],
      },
      smaller: {
        fontSize: [1, null, 2],
      },
    },
  },
  h4: {
    variants: {
      default: {
        fontSize: [2, null, 3],
        fontWeight: 'bold',
      },
      featureSubtitle: {
        fontSize: [1, null, 3],
        fontWeight: ['regular', null, 'semiBold'],
      },
      mainList: {
        fontSize: [1, null, 2],
        fontWeight: 'semiBold',
        textTransform: 'uppercase',
      },
      asideList: {
        fontSize: 2,
        fontWeight: 'extraBold',
        color: 'slate',
      },
      asideListItem: {
        fontSize: 2,
        fontWeight: 'bold',
        lineHeight: 'medium',
      },
    },
  },
};

export default heading;
