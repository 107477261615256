export default class MemoryTokenStorage {
  constructor() {
    this.items = Object.create(null);
  }

  get(key) {
    return this.items[key];
  }

  set(key, val) {
    this.items[key] = val;
    return this.get(key);
  }

  remove(key) {
    delete this.items[key];
  }
}
